import world_countries from "../images/projects/world-countries.jpg";
import ruhr_trips from "../images/projects/ruhr-trips.jpg";
import portfolio from "../images/projects/portfolio.jpg";
import bike_finder from "../images/projects/bike-finder.jpg";
import image_board from "../images/projects/image-board.jpg";
import covid_history from "../images/projects/covid-history.jpg";
import night_city from "../images/projects/night-city.png";
import chat_videocall from "../images/projects/chat-videocall.png";

export const projectsList = [
  {
    imgPath: chat_videocall,
    icons: ["React", "Typescript", "NodeJS", "SocketIO", "Tailwind"],
    title: "Chat & Videocall",
    description:
      "Chat functionality for one to one and one to many. Create and manage videocalls with up to four participants. Uses websockets to establish and manage peer to peer connections between the users.",
    liveLink: "https://chat-videocall.herokuapp.com",
    sourceLink: "https://github.com/PatrickB-Hub/chat-videocall",
  },
  {
    imgPath: night_city,
    icons: ["React", "Typescript", "Webgl"],
    title: "Night City",
    description:
      "Creative Cyberpunk2077 inspired landing page built with R3F. Uses custom glsl shaders for reflective material. Implements warp and distortion effects.",
    liveLink: "https://patrickb-hub.github.io/night-city/",
    sourceLink: "https://github.com/PatrickB-Hub/night-city",
  },
  {
    imgPath: covid_history,
    icons: ["Typescript", "Sass", "Webgl", "D3", "Webpack"],
    title: "Covid-19 Globe",
    description:
      "Visualization of Covid-19 infections. You can filter for active cases, recovered and deaths. The information is rendered on an interactive 3D globe and plotted on a line graph.",
    liveLink: "https://covid-history.de",
    sourceLink: "https://github.com/PatrickB-Hub/covid-globe",
  },
  {
    imgPath: image_board,
    icons: ["React", "Redux", "Typescript", "Docker", "NodeJS", "MongoDB"],
    title: "Image Board",
    description:
      "Post your images and view what your friends are posting. Comment and rate other posts. Register/Login functionality using JWT. Persistent data storage with MongoDB. The complete backend is ready to be deployed as a docker container.",
    liveLink: "https://patrickb-hub.github.io/image-board/",
    sourceLink: "https://github.com/PatrickB-Hub/image-board",
  },
  {
    imgPath: bike_finder,
    icons: ["React", "JavaScript", "HTML5", "CSS3"],
    title: "Bike Finder",
    description:
      "The app allows you to browse a list of missing and stolen bicycles. You can search for specific bikes and filter the results. Built with React.js, hooks and Context API.",
    liveLink: "https://patrickb-hub.github.io/bike-finder/",
    sourceLink: "https://github.com/PatrickB-Hub/bike-finder",
  },
  {
    imgPath: ruhr_trips,
    icons: ["HTML5", "CSS3", "Sass", "JavaScript"],
    title: "Ruhrtrips",
    description:
      "Landing Page featuring famous industrial sites of the Ruhr area. Fully responsive UI built with Sass and Vanilla Javascript.",
    liveLink: "https://patrickb-hub.github.io/ruhrtrips/",
    sourceLink: "https://github.com/PatrickB-Hub/ruhrtrips",
  },
  {
    imgPath: portfolio,
    icons: ["React", "JavaScript", "HTML5", "CSS3"],
    title: "Portfolio",
    description:
      "Personal portfolio website showcasing some of my projects, skills and information about me. Rounded off by my contact information and a contact form. Built with React and Styled-Components.",
    liveLink: "https://patrickbecker.me",
    sourceLink: "https://github.com/PatrickB-Hub/portfolio",
  },
  {
    imgPath: world_countries,
    icons: ["HTML5", "CSS3", "JavaScript"],
    title: "Countries of the World",
    description:
      "The application displays a list of all countries with their flag, captial and population. You can search for countries and sort them by name, capital or population. Built with Vanilla Javascript.",
    liveLink: "https://patrickb-hub.github.io/world-countries/",
    sourceLink: "https://github.com/PatrickB-Hub/world-countries",
  },
];
