import React from "react";
import styled from "styled-components";

import { P } from "../atoms/typography";
import { color_primary } from "../atoms/variables";

import { Container } from "./Container";

const Profile = () => {
  return (
    <Container title="About">
      <Wrapper>
        <P>
          Hey, I'm Patrick! Welcome to my spot on the web. I'm a software
          developer located in Germany with a serious passion for UI effects,
          animations and creating intuitive, dynamic user experiences. Check out
          the{" "}
          <Link href="#projects" rel="noopener">
            projects
          </Link>{" "}
          section to see a highlight of the open-source projects I've made.
          <br />
          <br />
          I am interested in the entire software development spectrum and
          working on ambitious projects with positive people. Well - organised
          person, problem solver, with high attention to detail. Fan of Sports,
          outdoor activities, TV series and English literature.
          <br />
          <br />
          You can contact me by email at{" "}
          <Link href="mailto: kontakt@patrickbecker.me" rel="noopener">
            kontakt@patrickbecker.me
          </Link>{" "}
          to say hi! I always appreciate meeting new people.
        </P>
      </Wrapper>
    </Container>
  );
};

export default Profile;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 4rem;
`;

const Link = styled.a`
  :link,
  :visited {
    color: ${color_primary}
    text-decoration: none;
  }

  :hover {
    border-bottom: 2px solid ${color_primary};
  }
`;
