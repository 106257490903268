import React from "react";

import { ReactComponent as JavaSvg } from "../images/skills/java.svg";
import { ReactComponent as PhpSvg } from "../images/skills/php.svg";
import { ReactComponent as JSSvg } from "../images/skills/javascript.svg";
import { ReactComponent as TypescriptSvg } from "../images/skills/typescript.svg";
import { ReactComponent as Html5Svg } from "../images/skills/html5.svg";
import { ReactComponent as Css3Svg } from "../images/skills/css3.svg";
import { ReactComponent as LaravelSvg } from "../images/skills/laravel.svg";
import { ReactComponent as ReactSvg } from "../images/skills/react.svg";
import { ReactComponent as ReduxSvg } from "../images/skills/redux.svg";
import { ReactComponent as SassSvg } from "../images/skills/sass.svg";
import { ReactComponent as TailwindCSSSvg } from "../images/skills/tailwindcss.svg";
import { ReactComponent as PostgreSQLSvg } from "../images/skills/postgresql.svg";
import { ReactComponent as MongoDBSvg } from "../images/skills/mongodb.svg";
import { ReactComponent as NodeJSSvg } from "../images/skills/node-dot-js.svg";
import { ReactComponent as SocketIOSvg } from "../images/skills/socket-dot-io.svg";
import { ReactComponent as AWSSvg } from "../images/skills/amazonaws.svg";
import { ReactComponent as WebglSvg } from "../images/skills/webgl.svg";
import { ReactComponent as D3Svg } from "../images/skills/d3-dot-js.svg";
import { ReactComponent as DockerSvg } from "../images/skills/docker.svg";
import { ReactComponent as WebpackSvg } from "../images/skills/webpack.svg";
import { ReactComponent as GitSvg } from "../images/skills/git.svg";
import { ReactComponent as GimpSvg } from "../images/skills/gimp.svg";
import { ReactComponent as FigmaSvg } from "../images/skills/figma.svg";

// https://simpleicons.org/
export const svg_icons = {
  Java: { color: "#007396", svg: <JavaSvg /> },
  PHP: { color: "#777BB4", svg: <PhpSvg /> },
  JavaScript: { color: "#F7DF1E", svg: <JSSvg /> },
  Typescript: { color: "#007ACC", svg: <TypescriptSvg /> },
  Laravel: { color: "#FF2D20", svg: <LaravelSvg /> },
  React: { color: "#61DAFB", svg: <ReactSvg /> },
  Redux: { color: "#764ABC", svg: <ReduxSvg /> },
  HTML5: { color: "#E34F26", svg: <Html5Svg /> },
  CSS3: { color: "#1572B6", svg: <Css3Svg /> },
  Sass: { color: "#CC6699", svg: <SassSvg /> },
  Tailwind: { color: "#38B2AC", svg: <TailwindCSSSvg /> },
  PostgreSQL: { color: "#336791", svg: <PostgreSQLSvg /> },
  MongoDB: { color: "#47A248", svg: <MongoDBSvg /> },
  NodeJS: { color: "#339933", svg: <NodeJSSvg /> },
  SocketIO: { color: "#010101", svg: <SocketIOSvg /> },
  AWS: { color: "#232F3E", svg: <AWSSvg /> },
  Webgl: { color: "#990000", svg: <WebglSvg /> },
  D3: { color: "#F9A03C", svg: <D3Svg /> },
  Docker: { color: "#2496ED", svg: <DockerSvg /> },
  Webpack: { color: "#8DD6F9", svg: <WebpackSvg /> },
  Git: { color: "#F05032", svg: <GitSvg /> },
  Gimp: { color: "#5C5543", svg: <GimpSvg /> },
  Figma: { color: "#F24E1E", svg: <FigmaSvg /> }
};
