const smoothScroll = id => {
  const target = document.getElementById(id);
  // distance of section-top from the top
  const targetPosition = target.offsetTop;
  // distance of windows-top from the top
  const startingPosition = window.pageYOffset;
  // distance
  const displacement = targetPosition - startingPosition;

  let startTime = null;

  const animationScroll = currentTime => {
    if (startTime === null) {
      startTime = currentTime;
    }

    let timeElapsed = currentTime - startTime;
    const run = easingFunction(
      timeElapsed,
      startingPosition,
      displacement,
      2000
    );
    window.scrollTo(0, run);

    // keep scrolling until duration or targetPosition is reached
    if (timeElapsed < 2000 && window.pageYOffset - targetPosition !== 0) {
      requestAnimationFrame(animationScroll);
    }
  };

  const easingFunction = (t, b, c, d) => {
    return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
  };

  // perform animation
  requestAnimationFrame(animationScroll);
};

export default smoothScroll;
